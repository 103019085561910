import React from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter as RouterProvider } from 'react-router-dom'
import { MuiThemeProvider } from '@material-ui/core/styles'
import * as theme from 'theme'

const AppProvider = ({ children, theme }) => (
  <RouterProvider>
    <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
  </RouterProvider>
)

AppProvider.defaultProps = {
  theme: theme.dark
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.object
}

export default AppProvider
