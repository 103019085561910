import React from 'react'
import Text from '@material-ui/core/Typography'
import Grow from '@material-ui/core/Grow'
import Button from '@material-ui/core/Button'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { compose, withProps } from 'recompose'
import * as theme from 'theme'
import { withStyle } from 'utils/styles'
import RequestAccessForm from '../containers/RequestAccessForm'
import ContentWrapper from './BlockContentWrapper'

const Wrapper = withStyle(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing.unit * 3,
  paddingBottom: theme.spacing.unit * 15,
  backgroundColor: '#FFF'
}))('section')

const RequestAccessBlock = () => (
  <MuiThemeProvider theme={theme.light}>
    <Wrapper>
      <Grow in timeout={525}>
        <ContentWrapper>
          <Text variant="h5" align="center">
            Keep Me Updated
          </Text>

          <RequestAccessForm />
        </ContentWrapper>
      </Grow>
    </Wrapper>
  </MuiThemeProvider>
)

const SubmitButton = compose(
  withProps({
    type: 'submit',
    fullWidth: true,
    color: 'primary',
    variant: 'contained'
  }),
  withStyle(({ theme }) => ({ marginTop: theme.spacing.unit * 2, borderRadius: 0 }))
)(Button)

export { RequestAccessBlock as default, SubmitButton }
