import { withStyle } from 'utils/styles'

const ContentWrapper = withStyle({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: 560,
  minHeight: 380,
  marginLeft: 'auto',
  marginRight: 'auto'
})('div')

export default ContentWrapper
