import React from 'react'
import { Route, Switch } from 'react-router-dom'
import BaseStyles from 'components/baseStyles/BaseStyles'
import HomePage from 'screens/homePage/HomePage'
import PrivacyPolicy from 'screens/homePage/PrivacyPolicy'
import Terms from 'screens/homePage/Terms'

const App = () => (
  <>
    <BaseStyles />
    <Switch>
      <Route exact path="/" component={HomePage} />
      <Route exact path="/privacy" component={PrivacyPolicy} />
      <Route exact path="/terms" component={Terms} />
      <Route component={HomePage} />
    </Switch>
  </>
)

export default App
