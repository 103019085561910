import React from 'react'
import LayoutWrapper from './components/LayoutWrapper'
import HeroBlock from './components/HeroBlock'
import RequestAccessBlock from './components/RequestAccessBlock'

const HomePage = () => (
  <LayoutWrapper>
    <HeroBlock />
    <RequestAccessBlock />
  </LayoutWrapper>
)

export default HomePage
