import React from 'react'
import Text from '@material-ui/core/Typography'
import { rgba } from 'polished'
import { withStyle } from 'utils/styles'
import laboratory from '../assets/laboratory.png'
import ContentWrapper from './BlockContentWrapper'
import Logo from './Logo'

const BgImageWrapper = withStyle(({ theme }) => ({
  position: 'relative',
  display: 'block',
  paddingTop: theme.spacing.unit * 2,
  paddingBottom: theme.spacing.unit * 2,
  paddingRight: theme.spacing.unit * 3,
  paddingLeft: theme.spacing.unit * 3,
  backgroundColor: '#142238',
  backgroundImage: `url(${laboratory})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: `linear-gradient(0deg, #555555 1%, ${rgba(
      '#000000',
      0.01
    )} 100%)`
  }
}))('section')

const TAGLINE = '&LAB'
const COMPANY_DESCRIPTION =
  'We create food supplements and personal pieces that make a difference.'

const HeroBlock = () => (
  <BgImageWrapper>
    <ContentWrapper>
      {/* <Logo style={{ marginBottom: 30 }} size={150} /> */}
      <Text variant="h4" gutterBottom style={{ textAlign: 'center' }}>
        {TAGLINE}
      </Text>
      <Text variant="body1" style={{ textAlign: 'center' }}>
        {COMPANY_DESCRIPTION}
      </Text>
    </ContentWrapper>
  </BgImageWrapper>
)

export default HeroBlock
