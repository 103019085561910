import React from 'react'
import Text from '@material-ui/core/Typography'

const styles = {
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: '#FFF',
    paddingTop: 24,
    paddingBottom: 120,
    paddingLeft: '10vw',
    paddingRight: '10vw'
  }
}

const PrivacyPolicy = () => (
  <main style={styles.container}>
    <Text variant="h4" gutterBottom style={{ color: '#333' }}>
      Data Policy
    </Text>

    <section style={{ marginBottom: 24 }}>
      <Text variant="body1" style={{ color: '#333' }}>
        This policy is used to inform users about our collection, use and
        disclosure of their personal information for providing and improving the
        features and services offered by Sauce. If you choose to use Sauce, then
        you agree to the collection and use of information related to this
        policy.
      </Text>
    </section>

    <section style={{ marginBottom: 24 }}>
      <Text variant="h5" style={{ color: '#333' }} gutterBottom>
        i. What information do we collect?
      </Text>

      <Text variant="body1" style={{ color: '#333' }} gutterBottom>
        <b>Information you provide.</b> We collect the content and other
        information you provide when you use our products, including when you
        sign up for an account or create a review.
      </Text>
      <Text variant="body1" style={{ color: '#333' }} gutterBottom>
        <b>Connections and interactions.</b> We collect information about the
        people, profiles and accounts you are connected to and how you interact
        with them on Sauce.
      </Text>
      <Text variant="body1" style={{ color: '#333' }} gutterBottom>
        <b>Your usage.</b> We collect information about how you use Sauce, such
        as the kinds of content you view or engage with; the features you use;
        the actions you take; the people or accounts you interact with; and the
        time, frequency and duration of your activities. For example, we might
        log what reviews and other content you view.
      </Text>
    </section>

    <section style={{ marginBottom: 24 }}>
      <Text variant="h5" style={{ color: '#333' }} gutterBottom>
        ii. How do we use this information?
      </Text>

      <Text variant="body1" style={{ color: '#333' }} gutterBottom>
        <b>Location-based information.</b> We use location-related information
        from your and others' use of the Sauce app, such as restaurant
        check-ins.
      </Text>
      <Text variant="body1" style={{ color: '#333' }} gutterBottom>
        <b>Research and development.</b> We use the information we have to
        develop, test and improve our products, including by conducting surveys
        and research, and testing and troubleshooting new products and features.
      </Text>
      <Text variant="body1" style={{ color: '#333' }} gutterBottom>
        <b>Analytics and other business services.</b> We use the information we
        have to help advertisers and other companies measure the effectiveness
        and distribution of our services and to understand the types of people
        who use our services and how people interact with the Sauce app.
      </Text>
      <Text variant="body1" style={{ color: '#333' }} gutterBottom>
        <b>Safety and security.</b> We use the information we have to verify
        accounts and activity, tackle harmful conduct, maintain the integrity of
        our products, and to promote safety and security on and off of the Sauce
        app.
      </Text>
      <Text variant="body1" style={{ color: '#333' }} gutterBottom>
        <b>Communications with you.</b> We may use the information we have to
        send you marketing communications, communicate with you about our
        products, and let you know about our policies and terms. We also use
        your information to respond to you when you contact us.
      </Text>
    </section>

    <section style={{ marginBottom: 24 }}>
      <Text variant="h5" style={{ color: '#333' }} gutterBottom>
        iii. How information is shared
      </Text>

      <Text variant="body1" style={{ color: '#333' }} gutterBottom>
        <b>Sharing on Sauce.</b> When you share reviews and check-ins using
        Sauce, you can see the audience for the post by viewing your list of
        followers. For removing a follower from the list, please contact
        support@sauce-app.com
      </Text>
      <Text variant="body1" style={{ color: '#333' }} gutterBottom>
        <b>Change of control or ownership.</b> If the control or ownership of
        Sauce App Limited changes, we may transfer your information to the new
        owner or executive board.
      </Text>
      <Text variant="body1" style={{ color: '#333' }} gutterBottom>
        <b>Sharing with third parties</b>
      </Text>
      <Text variant="body1" style={{ color: '#333' }} gutterBottom>
        We may work with third parties who help us provide and improve Sauce,
        such as analysing how Sauce is used or conducting surveys. We impose
        strict restrictions on how our partners are permitted to use the data we
        provide.
      </Text>
      <Text variant="body1" style={{ color: '#333' }} gutterBottom>
        We may share information with law enforcement entities or in response to
        legal requests if we have a reasonable belief that the law requires us
        to do so.
      </Text>
    </section>

    <section style={{ marginBottom: 24 }}>
      <Text variant="h5" style={{ color: '#333' }} gutterBottom>
        iv. The legal basis for processing data
      </Text>

      <ul>
        <li>
          <Text variant="body1" style={{ color: '#333' }}>
            as necessary to fulfil our Terms and Conditions;
          </Text>
        </li>
        <li>
          <Text variant="body1" style={{ color: '#333' }}>
            as necessary to comply with our legal obligations;
          </Text>
        </li>
        <li>
          <Text variant="body1" style={{ color: '#333' }}>
            as necessary in the public interest;
          </Text>
        </li>
        <li>
          <Text variant="body1" style={{ color: '#333' }}>
            as necessary for our (or others') legitimate interests, including
            our interests in providing an innovative and profitable service to
            our users and partners; and
          </Text>
        </li>
        <li>
          <Text variant="body1" style={{ color: '#333' }}>
            based on your consent, which you may revoke at any time by
            contacting support@sauce-app.com
          </Text>
        </li>
      </ul>
    </section>

    <section style={{ marginBottom: 24 }}>
      <Text variant="h5" style={{ color: '#333' }} gutterBottom>
        v. Your rights under the GDPR
      </Text>
      <Text variant="body1" style={{ color: '#333' }}>
        Under the General Data Protection Regulation, you have the right to
        access, rectify and erase your data by contacting support@sauce-app.com
      </Text>
    </section>

    <section style={{ marginBottom: 24 }}>
      <Text variant="h5" style={{ color: '#333' }} gutterBottom>
        vi. Data retention and account deletion
      </Text>
      <Text variant="body1" style={{ color: '#333' }}>
        We store data until it is no longer necessary to provide our services or
        until your account is deleted, whichever comes first. However,
        information we receive about you can be accessed and preserved for an
        extended period when it is the subject of a legal request or obligation,
        governmental investigation, or investigations of possible violations of
        our terms or policies, or otherwise to prevent harm. We also retain
        information from terminated accounts for term violations for at least a
        year to prevent repeat abuse or other term violations.
      </Text>
    </section>

    <section style={{ marginBottom: 24 }}>
      <Text variant="h5" style={{ color: '#333' }} gutterBottom>
        vii. Amendments to this policy
      </Text>
      <Text variant="body1" style={{ color: '#333' }}>
        We may update our Data Policy from time to time. We will notify you
        before we make changes to this policy and give you the opportunity to
        review the revised policy before you choose to continue using Sauce.
      </Text>
    </section>

    <section style={{ marginBottom: 24 }}>
      <Text variant="h5" style={{ color: '#333' }} gutterBottom>
        viii. Questions or complaints
      </Text>
      <Text variant="body1" style={{ color: '#333' }} gutterBottom>
        If you have questions about this policy or any complaints, you can
        contact us at support@sauce-app.com
      </Text>
      <Text variant="body1" style={{ color: '#333' }}>
        This policy is effective as of 30-07-2020
      </Text>
    </section>
  </main>
)

export default PrivacyPolicy
