import React from 'react'
import PropTypes from 'prop-types'

const Logo = ({ size, color, style }) => (
  <svg viewBox="0 0 487 640" width={size * 0.8} height={size} style={style}>
    {/* <path
      fill={color}
      d="M456.01 53.29C468.2 41.1 468.2 21.34 456.01 9.14C443.82 -3.05 424.04 -3.05 411.86 9.14C403.47 17.53 336.31 84.69 327.92 93.09C301.62 83.37 273.19 78.04 243.51 78.04C109.02 78.04 0 187.06 0 321.55C0 401.42 38.45 472.3 97.85 516.71C90.86 523.71 34.87 579.7 27.87 586.7C15.68 598.88 15.68 618.65 27.87 630.85C33.97 636.94 41.96 640 49.94 640C57.93 640 65.92 636.94 72.01 630.85C80.27 622.59 146.35 556.52 154.61 548.26C182.16 559.07 212.11 565.07 243.49 565.07C377.98 565.07 487 456.05 487 321.56C487 240.08 446.94 168.01 385.5 123.8C399.6 109.7 448.96 60.34 456.01 53.29ZM204.56 498.31C218.47 484.4 329.71 373.16 343.62 359.25C355.82 347.06 355.82 327.3 343.62 315.1C331.43 302.91 311.65 302.91 299.48 315.1C283.8 330.78 158.37 456.2 142.69 471.88C94.32 439.34 62.43 384.11 62.43 321.56C62.43 221.71 143.66 140.49 243.49 140.49C255.03 140.49 266.29 141.69 277.24 143.77C263.54 157.47 153.95 267.05 140.25 280.75C128.05 292.94 128.05 312.7 140.25 324.9C146.34 331 154.34 334.05 162.32 334.05C170.3 334.05 178.3 331 184.39 324.9C200 309.3 324.82 184.47 340.42 168.87C390.9 201.04 424.56 257.38 424.56 321.55C424.57 421.41 343.34 502.64 243.49 502.64C230.12 502.64 217.12 501.07 204.56 498.31Z"
    /> */}
    <path
      d="M77.2,126.7c-3.4,0-6.5-0.6-9-1.8s-4.6-2.8-6-4.9c-1.4-2.1-2.2-4.6-2.2-7.4c0-2.1,0.3-3.9,0.9-5.6c0.6-1.7,1.5-3.2,2.8-4.7
      c1.2-1.5,2.8-2.9,4.6-4.2c1.9-1.3,4.1-2.6,6.6-3.9l2.2-1c2.4-1.4,4.5-2.7,6.2-3.9c1.7-1.2,3.1-2.6,4-4.1c1-1.5,1.5-3.4,1.5-5.6
      c0-2.4-0.8-4.2-2.3-5.6c-1.5-1.3-3.6-2-6.2-2c-2.8,0-5,0.8-6.5,2.4c-1.5,1.6-2.3,3.6-2.3,6c0,1.2,0.2,2.3,0.5,3.4
      c0.3,1.1,1,2.4,1.9,3.9c1,1.5,2.4,3.5,4.3,6s4.4,5.8,7.6,9.9c3.3,4.2,6.1,7.6,8.3,10.2c2.2,2.6,4.1,4.6,5.6,6
      c1.5,1.4,2.9,2.3,4.2,2.8s2.7,0.8,4.2,0.8l-1,3.3c-1.6,0-3.1-0.3-4.6-0.8c-1.4-0.5-3-1.5-4.7-3c-1.7-1.5-3.8-3.6-6.1-6.3
      c-2.4-2.8-5.3-6.3-8.7-10.6c-3-3.8-5.6-7.1-7.5-9.6c-2-2.6-3.6-4.8-4.7-6.5c-1.2-1.8-2-3.4-2.5-4.8c-0.5-1.4-0.7-3-0.7-4.6
      c0-2.2,0.5-4.2,1.5-6c1-1.8,2.5-3.3,4.4-4.4c2-1.1,4.4-1.6,7.2-1.6c2.4,0,4.5,0.4,6.3,1.3c1.8,0.9,3.2,2.1,4.2,3.7
      c1,1.6,1.5,3.6,1.5,5.8c0,2.4-0.5,4.6-1.4,6.5s-2.4,3.6-4.3,5.2c-2,1.6-4.5,3.3-7.7,5.1l-1.8,0.7c-2.9,1.5-5.4,3.1-7.4,4.5
      c-2,1.5-3.5,3.1-4.4,4.8c-1,1.7-1.5,3.8-1.5,6.3c0,2.2,0.6,4.2,1.8,5.8c1.2,1.6,2.8,2.9,4.8,3.8c2,0.9,4.3,1.3,6.8,1.3
      c3.4,0,6.4-0.7,8.9-2c2.5-1.3,4.6-3.1,6.5-5.4l0.6-0.7c1.8-2.6,3.1-5.6,4-9s1.2-7.1,1-11.1l3.8,0.3c0.1,3.8-0.3,7.7-1.2,11.6
      c-0.9,3.9-2.5,7.3-4.8,10.3l-0.8,1.1c-2,2.6-4.5,4.6-7.5,6.2C84.9,125.9,81.3,126.7,77.2,126.7z"
    />
    <path d="M124.2,121.9h34.1v4h-38.4V69h4.3V121.9z" />
    <path d="M165.3,125.8L188,69h5.1l22.7,56.8h-4.6l-18.6-46.6l-2.1-6h-0.1l-2.1,5.9l-18.6,46.7H165.3z M175.4,108v-4h30.2v4H175.4z" />
    <path d="M229.8,125.8V69h20.1c6.3,0,10.9,1.3,13.9,3.9c3,2.6,4.6,6.3,4.6,11.1c0,3.3-0.9,6.1-2.7,8.4c-1.8,2.3-4.4,3.8-7.7,4.5v0.1
      c3.7,0.6,6.6,2.1,8.7,4.5c2.2,2.4,3.2,5.4,3.2,8.9c0,4.8-1.7,8.6-5.2,11.3c-3.4,2.7-8.4,4-14.9,4H229.8z M234.1,95.2H250
      c9.2,0,13.9-3.7,13.9-11.1c0-7.4-4.6-11.1-13.9-11.1h-15.8V95.2z M234.1,121.9h16.6c4.9,0,8.5-1,11-2.9c2.4-2,3.7-4.8,3.7-8.4
      c0-3.7-1.2-6.6-3.7-8.5c-2.4-1.9-6.1-2.9-11-2.9h-16.6V121.9z"
    />
  </svg>
)

Logo.defaultProps = {
  style: {},
  color: '#EA5A64'
}

Logo.propTypes = {
  size: PropTypes.number.isRequired,
  style: PropTypes.object /* eslint-disable-line */,
  color: PropTypes.string
}

export default Logo
