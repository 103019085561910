import { withStyle } from 'utils/styles'

const LayoutWrapper = withStyle({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh'
})('div')

export default LayoutWrapper
