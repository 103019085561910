import { createMuiTheme } from '@material-ui/core/styles'
import cyan from '@material-ui/core/colors/cyan'

const light = createMuiTheme({
  typography: { useNextVariants: true },
  palette: {
    primary: { main: '#EA5A64' }
  }
})

const dark = createMuiTheme({
  typography: { useNextVariants: true },
  palette: { type: 'dark', primary: cyan }
})

export { light, dark }
