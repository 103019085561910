import React from 'react'
import { withProps } from 'recompose'
import TextFieldBase from '@material-ui/core/TextField'
import { SubmitButton } from '../components/RequestAccessBlock'

const TextField = withProps({
  fullWidth: true,
  margin: 'normal'
})(TextFieldBase)

const RequestForm = () => (
  <form method="post" action="https://submit-form.com/OjqdJQuVvDTFOAP5rnpdV" target="_self">
    <TextField name="name" label="Name" id="name" required />
    <TextField
      name="email"
      label="Email address"
      type="email"
      autoComplete="email"
      required
    />
    <SubmitButton>Send Request</SubmitButton>
  </form>
)

export default RequestForm
