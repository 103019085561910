import React from 'react'
import Text from '@material-ui/core/Typography'

const styles = {
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: '#FFF',
    paddingTop: 24,
    paddingBottom: 120,
    paddingLeft: '10vw',
    paddingRight: '10vw'
  }
}

const Terms = () => (
  <main style={styles.container}>
    <Text variant="h4" gutterBottom style={{ color: '#333' }}>
      Terms and Conditions
    </Text>

    <section style={{ marginBottom: 24 }}>
      <Text variant="body1" style={{ color: '#333' }}>
        By signing up, these terms will automatically apply to you. Therefore,
        you should make sure you read these terms carefully before using Sauce
        app. You are not allowed to copy or modify any part of Sauce app or our
        trademarks in any way. You are not allowed to attempt to extract the
        source or object code of the app; translate the app into other
        languages; or make derivative versions. Sauce app itself, and all the
        trademarks, copyright, database rights and other intellectual property
        rights related to it, belong to Sauce App Limited.
      </Text>
    </section>

    <section style={{ marginBottom: 24 }}>
      <Text variant="h5" style={{ color: '#333' }} gutterBottom>
        Your Commitments
      </Text>

      <Text variant="body1" style={{ color: '#333' }} gutterBottom>
        In return for our commitment to provide Sauce, we require you to make
        the below commitments to us.
      </Text>
      <Text variant="body1" style={{ color: '#333' }} gutterBottom>
        <b>User requirements.</b> We want Sauce to be safe, secure, and in
        accordance with the law. To use Sauce, you must be at least 13 years old
        and you must not be prohibited from receiving any part of our product
        under applicable laws.
      </Text>
      <Text variant="body1" style={{ color: '#333' }} gutterBottom>
        <b>Inaccurate information.</b> You must provide us with accurate and up
        to date information. Also, you cannot create an account for someone else
        unless you have their express permission.
      </Text>
      <Text variant="body1" style={{ color: '#333' }} gutterBottom>
        <b>Prohibited activities.</b> You cannot do anything unlawful,
        misleading, or fraudulent or for an illegal or unauthorised purpose, or
        violate these Terms and Conditions or encourage others to do so. You
        cannot attempt to create accounts or access or collect information in
        unauthorised ways or do anything to interfere with or impair the
        intended operation of Sauce. This includes creating accounts or
        collecting information in an automated way without our express
        permission. You cannot post private or confidential information or do
        anything that violates someone else's rights, including intellectual
        property.
      </Text>
      <Text variant="body1" style={{ color: '#333' }}>
        <b>Intellectual property.</b> If you use content covered by our
        intellectual property rights, we retain all rights to our content (but
        not yours). You can only use our intellectual property and trademarks or
        similar marks with our prior written permission. You must obtain written
        permission from us or under an open source license to modify, create
        derivative works of, decompile, or otherwise attempt to extract source
        code from us.
      </Text>
    </section>

    <section style={{ marginBottom: 24 }}>
      <Text variant="h5" style={{ color: '#333' }} gutterBottom>
        Permissions you give to us.
      </Text>

      <Text variant="body1" style={{ color: '#333' }} gutterBottom>
        <b>Content ownership and license.</b> We do not claim ownership of your
        content that you post on or through Sauce. Instead, when you share,
        post, or upload content that is covered by intellectual property rights
        (like photos or videos) on or in connection with Sauce, you hereby grant
        to us a non-exclusive, royalty- free, transferable, sub-licensable,
        worldwide license to host, use, distribute, modify, run, copy, publicly
        perform or display, translate, and create derivative works of your
        content. You can end this license at any time by deleting your content
        or account.
      </Text>
      <Text variant="body1" style={{ color: '#333' }} gutterBottom>
        <b>Username, profile picture and your activities.</b> You give us
        permission to show your username, profile picture, and information about
        your actions (such as reviews) or relationships (such as follows) in
        connection with accounts. If you select a username or similar identifier
        for your account, we may change it if we believe it is appropriate or
        necessary.
      </Text>
      <Text variant="body1" style={{ color: '#333' }} gutterBottom>
        <b>Content removal or account termination.</b> We can remove any content
        or information you share on Sauce if we believe that it violates these
        Terms and Conditions or we are required to do so by law. We can refuse
        to provide or stop providing all or part of Sauce to you (including
        terminating or disabling your account) immediately if you violate these
        Terms and Conditions; if you infringe other people's intellectual
        property rights; or where we are required to do so by law. If we take
        action to disable or terminate your account, we will notify you where
        appropriate. If you believe your account has been terminated in error,
        or you want to disable your account please email support@sauce-app.com.
      </Text>
      <Text variant="body1" style={{ color: '#333' }} gutterBottom>
        <b>Our Agreement.</b> If any aspect of this agreement is unenforceable,
        the rest will remain in effect. Any amendment or waiver to our agreement
        must be in writing and signed by us. If we fail to enforce any aspect of
        this agreement, it will not be a waiver. We reserve all rights not
        expressly granted to you.
      </Text>
      <Text variant="body1" style={{ color: '#333' }} gutterBottom>
        <b>Third-party rights.</b> This agreement does not give rights to any
        third parties. You cannot transfer your rights or obligations under this
        agreement without our consent. Our rights and obligations can be
        assigned to others. For example, this could occur if our ownership
        changes (as in a merger, acquisition, or sale of assets) or by law.
      </Text>
      <Text variant="body1" style={{ color: '#333' }} gutterBottom>
        <b>Who is responsible if something happens.</b> We will use reasonable
        skill and care in providing Sauce to you and in keeping a safe, secure,
        and error-free environment, but we cannot guarantee that Sauce will
        always function without disruptions, delays, or imperfections. Provided
        we have acted with reasonable skill and care, we do not accept
        responsibility for: losses not caused by our breach of these terms or
        otherwise by our acts; losses which are not reasonably foreseeable by
        you and us at the time of entering into these terms; any offensive,
        inappropriate, obscene, unlawful, or otherwise objectionable content
        posted by others that you may encounter on Sauce; and events beyond our
        reasonable control. The above does not exclude or limit our liability
        for death, personal injury, or fraudulent misrepresentation caused by
        our negligence. It also does not exclude or limit our liability for any
        other things where the law does not permit us to do so.
      </Text>
      <Text variant="body1" style={{ color: '#333' }} gutterBottom>
        <b>Disputes.</b> Any dispute or claim arising out of or in connection
        with this agreement or its subject matter will be governed by and
        construed in accordance with the laws of England and Wales and the
        courts of England and Wales will have exclusive jurisdiction to settle
        any dispute or claim that arises out of or in connection with the
        provision of services outlined in this letter or its subject matter or
        formation, including non-contractual disputes or claims.
      </Text>
      <Text variant="body1" style={{ color: '#333' }} gutterBottom>
        <b>Unsolicited Material.</b> We always appreciate feedback or other
        suggestions but may use them without any restrictions or obligation to
        compensate you for them and are under no obligation to keep them
        confidential.
      </Text>
      <Text variant="body1" style={{ color: '#333' }}>
        <b>Updating These Terms.</b> We may change our Service and policies, and
        we may need to make changes to these Terms so that they accurately
        reflect our service and policies. Unless otherwise required by law, we
        will notify you before we make changes to these terms and give you an
        opportunity to review them before they go into effect. Then, if you
        continue to use Sauce, you will be bound by the updated terms. If you do
        not want to agree to these or any updated terms, you can delete your
        account.
      </Text>
    </section>
  </main>
)

export default Terms
